<template>
  <div>

    <div ref="problems" style="z-index: 10">
      <v-container
        style="max-width: 1400px !important; z-index: 10"
        :class="$vuetify.breakpoint.xs ? 'pa-0' : ''"
      >
        <div :class="$vuetify.breakpoint.smAndDown ? 'col-12' : 'col-9'">
          <div style="max-width: 800px"
               class="mx-auto d-flex flex-column"
          >

            <v-breadcrumbs :items="breadcrumb"
                           divider="/"
                           style="z-index: 10"
                           class="pa-0 d-inline-block text-truncate mb-5">
            </v-breadcrumbs>

          </div>
        </div>


        <v-row
          no-gutters
          class="pa-0"
          style="margin: auto; background-color: rgb(244, 244, 244) !important;z-index: 10; flex: 1"
        >
          <!-------------------------------------->
          <!-- Problemes Container              -->
          <!-------------------------------------->
          <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 9"
                 class="align-center"
                 style="z-index: 2"
          >

            <div style="z-index: 99 !important; max-width: 800px; margin: auto;"
                 class="d-flex flex-column"
                 :class="$vuetify.breakpoint.xs ? 'px-2' : ''"
            >

            <span
              v-if="params"
              class="font-rubik font-weight-bold"
              style="font-size: 26px; z-index: 99 !important; color: black !important;"
            >
                {{ getTopicName() }}
            </span>

              <span
                v-if="problem"
                class="font-rubik font-weight-medium blue-grey--text text--darken-3"
                style="z-index: 10 !important; font-size: 18px"
              >
                  Problema  del {{ problem.year }}, {{ problem.month.toLowerCase() }}, sèrie {{ problem.serie }}
                </span>


            </div>

            <v-row style="margin: auto; flex: 1"
                   class="mt-1"
            >

              <v-col
                style="max-width: 800px; margin: auto; flex: 1"
                class="pa-0">


                <problem-card
                  v-if="problem"
                  :problem="problem"
                  :selectedProblem.sync="selectedProblem"
                  :allProblemsIds="allProblemsIds"
                  class="mt-6"
                  @toLeft="toLeft"
                  @toRight="toRight"
                ></problem-card>


                <horizontal-ads-after-solution
                  v-if="problem && !loading && ($root.currentUser === null || !$root.currentUser.premium)"
                ></horizontal-ads-after-solution>

              </v-col>

            </v-row>


          </v-col>


          <!-------------------------------------->
          <!-- Right Column                     -->
          <!-------------------------------------->
          <v-col v-if="!$vuetify.breakpoint.smAndDown && !loading" cols="3" style="position: relative; z-index: 2">

            <div style="max-width: 300px; position: sticky; top: 100px; left: 0"
                 class="px-4"
            >


              <anuncis-verticals :color="getColor"
                                 :selectedProblem="selectedProblem"
              ></anuncis-verticals>

              <examens-recomenats
                :color="getColor"
                :exams="subjectInfo ? subjectInfo['other_topics'] : []"
              ></examens-recomenats>

              <v-card
                class="my-2 rounded-lg"
                height="250" style="z-index: 99"
                width="300" flat color="transparent">

                <adsense ad-slot="6844584728"
                         style="display:inline-block;width:300px;height:250px;z-index: 99"
                ></adsense>

              </v-card>

            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>


    <div
      style="height: 200px; position: absolute; top: 0; left: 0; width: 100%; z-index: 1; opacity: 0.7;"
      class="d-flex"
      :style="`background: linear-gradient(to bottom,rgba(244,244,244,0.01),rgba(244,244,244, 1)),${getColor}`"
    >
    </div>

    <all-problems
      v-if="problems && !loading"
      :problems="problems"
      :selectedProblem="selectedProblem"
      @fetchProblems="fetchProblems"
    ></all-problems>


    <v-dialog v-model="showAdsDialog" max-width="fit-content" content-class="elevation-0">
      <div style="position:relative;">
        <router-link to="/dossiers-selectivitat">
          <img
            :src="adsDialogImage"
            style="width: 100%; max-height: 600px; border-radius: 16px !important;"
            contain
          >
        </router-link>

        <v-icon
          @click="showAdsDialog=false"
          size="18"
          class="grey lighten-3 rounded-sm"
          style="position: absolute; right: 15px; top: 15px">{{ mdiClose() }}
        </v-icon>
      </div>
    </v-dialog>

  </div>
</template>

<script>

import {mdiClose} from "/src/assets/mdi.json";
import ExamensRecomenats from "./recommended-exams/ExamensRecomenats.vue";
import AnuncisVerticals from "./ads/AnuncisVerticals.vue";
import ProblemCard from "./problem-card/ProblemCard.vue";
import HorizontalAdsAfterSolution from "./ads/HorizontalAdsAfterSolution.vue";
import AllProblems from "./all-problems/AllProblems.vue";
import Adsense from "../../../components/anuncis/Adsense.vue";

export default {
  name: 'ProblemesPerTemes',
  components: {Adsense, AllProblems, HorizontalAdsAfterSolution, ProblemCard, AnuncisVerticals, ExamensRecomenats},
  metaInfo() {
    return {
      title: `${this.seoTitle}`,
      titleTemplate: '%s - examenselectivitat',
      meta: [
        {charset: 'utf-8'},
        {
          name: 'description',
          content: `Els problemes ${this.deApostrof(this.getTopicName())} ordenats per temes i amb la solució en un clic.`
        },
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  mounted() {
    this.$forceUpdate();
  },
  data() {
    return {
      subject: null,
      subjectInfo: null,
      loading: true,
      url: process.env.VUE_APP_AXIOS_URI,
      problems: [],
      problem: null,
      selectedProblem: null,
      showAdsDialog: false,
      adsDialogImage: "https://examenselectivitat.cat:3000/api/images/other/apunts-dialog-diagonal.webp",
      allProblemsIds: []
    }
  },
  computed: {
    seoTitle() {
      return `${this.getTopicName()}: Tots els problemes que han sortit a la selectivitat`
    },
    params() {
      return this.$route.params
    },
    breadcrumb() {
      return [
        {
          text: 'Assignatures',
          disabled: false,
          exact: true,
          to: '/',
        },
        {
          text: this.$route.params.subject_name,
          disabled: false,
          exact: true,
          to: '/selectivitat/' + this.$route.params.subject_name,
        },
        {
          text: this.getTopicName(),
          disabled: true,
          to: '',
        },
      ]
    },
    getColor() {

      const colors = [
        "#FF8FB1",
        "#256EFF",
        "#FFA201",
        "#2FCA9F",
        "#C47AFF",
        "#40C4FF",
        "#A460ED",
        "#8C9EFF",
        "#2F89FC",
        "#00BFA5",
        "#ffa63c",
      ]

      return this.subjectInfo ? colors[(this.subjectInfo.subject_id) % colors.length] : 'rgba(244,244,244, 1)'
    },
  },
  methods: {
    mdiClose() {
      return mdiClose
    },
    getTopicName() {
      return this.subjectInfo && this.subjectInfo.topic_name ? this.subjectInfo.topic_name : null
    },
    resetValues() {
      this.subjectInfo = null
      this.loading = true
      this.problems = []
      this.problem = null
      this.selectedProblem = null
    },
    fetchSubjectInfo: async function () {
      const subject_name = this.$route.params.subject_name
      const topic_path = this.$route.params.topic_path;

      const {data} = await this.axios.get(`/subject-info/topic/${subject_name}/${topic_path}`)

      this.subjectInfo = data
      this.allProblemsIds = data["problems_id"].split(";").map(p => parseInt(p));
    },
    deApostrof(assig) {
      if (assig) {
        const lletra = assig.charAt(0).toLowerCase()
        return lletra === 'a' || lletra === 'e' || lletra === 'i' || lletra === 'o' || lletra === 'u' || lletra === 'h' ? "d'" + assig : 'de ' + assig
      }
    },
    async fetchProblems() {
      if (this.problems.length < this.allProblemsIds.length) {
        try {
          const subject_name = this.$route.params.subject_name;
          const topic_path = this.$route.params.topic_path;
          const offset = this.problems.length;
          const {data} = await this.axios.get(`/problems/topic/${subject_name}/${topic_path}/${offset}`);

          this.problems = this.problems.concat(...data["problems"])

          await this.setProblem()
        } catch (error) {
          if (error.response.data.captchaRequired) {
            this.$root.showCaptcha = true;
          }
        }
      }
    },
    async setProblem() {
      const query = this.$route.query;
      // Problem on query
      if (query && query['problem']) {
        const index = this.problems.findIndex(p => p['problem_id'] === parseInt(query['problem']));
        // Found on actual list
        if (index >= 0) {
          this.problem = this.problems[index];
          this.selectedProblem = index;
        }
        // Not found in actual problems list
        else {
          await this.fetchOneProblem(query['problem']);
        }
      }
      // Default case: no selected problem
      else if (this.selectedProblem === null) {
        this.selectedProblem = 0;
        this.problem = this.problems[this.selectedProblem];
      }

    },
    async fetchOneProblem(problem_id) {
      const {data} = await this.axios.get(`/problem/${problem_id}`);
      this.problem = data
    },
    toLeft() {
      this.selectedProblem--;
      this.problem = this.problems[this.selectedProblem];
      // this.$router.replace({ name: "user-view", params: {id:"123"}, query: {q1: "q1"} })
      this.$router.replace({query: {problem: this.problem["problem_id"]}})
    },
    toRight() {
      this.selectedProblem++;
      this.problem = this.problems[this.selectedProblem];
      this.$router.replace({query: {problem: this.problem["problem_id"]}})
    }
  },
  watch: {
    '$root.showCaptcha'(val) {
      if (!val)
        this.fetchProblems()
    },
    '$route.params':
      {
        immediate: true,
        async handler(val, old) {
          this.resetValues()
          await this.fetchSubjectInfo()
          await this.fetchProblems()

          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0


          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      },
    '$route.query':
      {
        immediate: true,
        async handler(val, old) {
          if (Object.keys(val).length > 0) {
            await this.setProblem()
          }

          // document.body.scrollTop = 0
          // document.documentElement.scrollTop =

        }
      },
    selectedProblem:
      {
        immediate: true,
        async handler(val, old) {
          if (val) {
            // console.log("selectedProblem: ", val, "vs", this.totalProblems - 1)
          }
          // if (val && val === this.totalProblems - 1) {
          //   await this.fetchProblems()
          // }
        }
      }
  }
}
</script>

<style scoped>

.fade-enter-active {
  transition: all 0.4s ease;
}

.fade-enter {
  opacity: 0;
}


.slide-fade-enter-active {
  transition: all 1s ease;
}


.slide-fade-enter {
  transform: translateY(-30px);
  opacity: 0;
}

ins.adsbygoogle[data-ad-status="unfilled"] a {
  display: block;
}

.thin-border {
  border: 2px solid #E5E7EB !important;
}


</style>
